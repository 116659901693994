/* src/app/history/shipment-detail/shipment-detail.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.shipment-details-dialog {
  font-size: 1rem;
}
.shipment-details-dialog .mat-mdc-dialog-content {
  padding: 0;
  position: relative;
}
.shipment-details-dialog .actions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  max-width: 44.25rem;
  padding: 2em;
  margin-bottom: -3em;
}
@media screen and (min-width: 48rem) {
  .shipment-details-dialog .actions {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 2.5em 0 2.5em 2em;
  }
}
.shipment-details-dialog .actions .mat-mdc-raised-button {
  margin-bottom: 1em;
}
@media screen and (min-width: 48rem) {
  .shipment-details-dialog .actions .mat-mdc-raised-button {
    margin-right: 1em;
  }
}
.ups-dialog .dialog-header .mat-mdc-dialog-actions .btn {
  margin-left: 1rem;
}
/*# sourceMappingURL=shipment-detail.component.css.map */
